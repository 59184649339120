/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { CtaProps } from 'components/flex/Cta/CtaShell'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  @media (max-width: 1599px) {
    min-height: 560px;
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1600px) {
    position: relative !important;
  }
  @media (max-width: 1599px) {
    width: 800px;
    height: 560px;
    position: absolute !important;
  }
  @media (max-width: 1399px) {
    height: 560px;
    width: 680px;
    position: absolute !important;
  }
  @media (max-width: 1199px) {
    height: 460px;
    width: auto;
    position: relative !important;
  }
  @media (min-width: 576px) {
    top: 0;
    left: 0;
  }
  @media (max-width: 575px) {
    height: 300px;
  }
`

const Icon = styled(Plaatjie)`
  width: 17px;
  height: 17px;
`

const Links = styled.div`
  & a {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 36px;
    margin-bottom: 2rem;
    & > strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const CtaDefault: React.FC<CtaProps> = ({ fields }) => (
  <Section className="position-relative mb-5">
    <div className="container h-100">
      <div className="row h-100 align-items-center justify-content-end">
        <div className="col-xl-7">
          <Image image={fields.image} alt="" />
        </div>
        <div className="col-xl-5 mt-4 mt-xl-0">
          <div className="px-lg-4 py-lg-5 pb-4">
            <Content content={fields.description || ''} />
            <Links className="d-flex flex-column flex-sm-row mt-5">
              {fields.links?.map((l, index) => (
                <div key={index} className="d-flex align-items-center">
                  <a href={l?.link?.url} className="me-5">
                    <Icon image={l?.icon} alt="" className="me-2" />
                    {l?.link?.title}
                  </a>
                </div>
              ))}
            </Links>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default CtaDefault
