/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import parse from 'html-react-parser'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'

// Elements
import { CtaProps } from 'components/flex/Cta/CtaShell'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimary'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Context
import { ProjectContext } from 'templates/project'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.light};
`

const Image = styled(Plaatjie)`
  height: 100%;
  @media (min-width: 1600px) {
    width: 610px;
    position: absolute !important;
  }
  @media (max-width: 1599px) {
    width: 500px;
    position: absolute !important;
  }
  @media (max-width: 1199px) {
    position: relative !important;
    width: 100%;
  }

  @media (min-width: 1599px) {
    height: 620px;
  }
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;
  background: ${({ theme }) => theme.color.light};

  & .form-duuf-group {
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #f2f2f2;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding: 10px 20px;
    background-color: #f2f2f2;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-group-select {
    width: 100% !important;

    & > div > div {
      background-color: rgb(242, 242, 242) !important;
      border-radius: 0;
      border: 0;
      padding: 6px 5px;
    }
  }

  & .info-message {
    opacity: 0.6;
  }

  & .form-duuf-group-submit {
    margin-bottom: unset;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: green;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const CtaForm: React.FC<CtaProps> = ({ fields }) => {
  const selectLiteral: { [key: string]: string } = {
    aluminium: 'Aluminium Kozijnen',
    kunststof: 'Kunststof Kozijnen',
    default: 'Kunststof Kozijnen',
  }

  const context = useContext(ProjectContext)

  const { projectCategory } = context

  return (
    <Section className="position-relative mb-5 pb-lg-5">
      <Image image={fields.image} alt="" />
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-end">
          <div className="col-xl-8">
            <div className="p-lg-5">
              <div className="ps-lg-5 pt-5 pt-lg-0">
                {parse(fields.description || '', {
                  replace: (domNode) => {
                    const { type } = domNode

                    if (type === 'text') {
                      // @ts-ignore
                      const replaceableString = domNode.data.match(/\{(.+?)\}/g)

                      let replace = ''

                      if (replaceableString && replaceableString[0]) {
                        // eslint-disable-next-line prefer-destructuring
                        replace = replaceableString[0]
                      }

                      if (replace !== '') {
                        return (
                          <>
                            {/* @ts-ignore */}
                            {domNode.data.replace(
                              replace,
                              projectCategory.toLowerCase()
                            )}
                          </>
                        )
                      }
                    }

                    return null
                  },
                })}
                <StyledFormDuuf
                  id={2}
                  generate={false}
                  defaultFields={{
                    input_10:
                      selectLiteral![
                        projectCategory?.toLowerCase() || 'default'
                      ],
                  }}
                  className="mt-5 p-4"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <FormDuufGroup hideIds={[7, 1, 2, 10]} />
                    </div>
                    <div className="col-lg-6">
                      <FormDuufGroup showIds={[7, 1, 2]} hideIds={[10]} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <FormDuufGroup showIds={[10]} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                      <FormDuufSubmit />
                      <span className="info-message ms-4">
                        Wij nemen zo spoedig mogelijk contact met u op.
                      </span>
                    </div>
                  </div>
                </StyledFormDuuf>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CtaForm
